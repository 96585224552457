body {
  margin: 0;
  background-color: #b2b2b2;
}

h1 {
  margin: 0;
  font-size: min(16vh, 16vw);
  text-align: center;
}

p {
  font-size: x-large;
  font-weight: bold;
  color: #3c4048;
}

h6 {
  margin: 0;
}

h4 {
  margin: 0;
}

header {
  display: flex;
  padding: 0 2vw;
  align-items: center;
  font-size: xx-large;
  font-weight: bolder;
  text-align: center;
  line-height: 13vh;
  background-color: #3c4048;
  color: #00abb3;
}

.header-child {
  flex: 1;
  display: flex;
  justify-content: center;
}

.header-child:first-child > * {
  margin-right: auto;
}

.header-child:last-child > * {
  margin-left: auto;
}

.content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20vh;
}

.board {
  width: min(70vh, 70vw);
  height: min(70vh, 70vw);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: black;
  padding: 3px;
}

.square {
  flex: 0 0 32%;
  height: 32%;
  background-color: #eaeaea;
  margin-top: 4px;
  cursor: pointer;
}

.leaderboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  padding-bottom: 1vh;
}

.leaderboard-cell {
  display: flex;
  width: 50vw;
  padding-left: 1vw;
}
